export const prescreenStatusColors = {
    APPROVED: 'green',
    PENDING: 'blue',
    REJECTED: 'red',
};

export const onboardingStatusColors = {
    IN_PROGRESS: 'blue',
    COMPLETE: 'green',
};

export const inviteStatusColors = {
    APPROVED: 'green',
    IN_PROGRESS: 'blue',
    MISSING_INFORMATION: 'orange',
    REJECTED: 'red',
};

export const passwordStatusColors = {
    CONFIRMED: 'green',
    COMPROMISED: 'red',
};

import React from 'react';

export default function Badge({ color = 'teal', children = '' }) {
    return (
        <span
            className={`inline-flex bg-${color}-600 text-white h-5 w-5 ml-1 text-xs font-bold rounded-full items-center justify-center`}
        >
            {children}
        </span>
    );
}

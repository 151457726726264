import React, { useState, useEffect, useCallback, Fragment } from 'react';

const SectionProgress = ({ validation }) => {
    let label = 'Optional';
    let color = 'blue';

    if (!validation.isOptional) {
        const complete = validation.errors.length === 0;
        label = complete ? 'Complete' : 'Incomplete';
        color = complete ? 'green' : 'red';
    }

    return (
        <span
            className={`ml-auto rounded text-xs px-1 bg-${color}-100 text-${color}-700 border border-${color}-700`}
        >
            {label}
        </span>
    );
};

const SectionMissingInfo = ({ validation }) => {
    if (validation.errors.length === 0) {
        return null;
    }

    return (
        <>
            {validation.errors.map((error) => (
                <Fragment key={error}>
                    <dt className="sm:w-1/2 py-1 font-bold text-red-700">
                        {error.split(' | ')[0]}
                    </dt>
                    <dd className="sm:w-1/2 pl-3 py-1 text-red-700">Not provided</dd>
                </Fragment>
            ))}
        </>
    );
};

const UserOnboardingDetails = ({ onboarding }) => {
    const [summary, setSummary] = useState({});

    const getSummary = useCallback(async () => {
        const summary = await onboarding.summary();
        setSummary(summary);
    }, [onboarding]);

    useEffect(() => {
        getSummary();
    }, [getSummary]);

    return (
        <div className="bg-gray-100 px-6 py-3">
            {Object.values(summary).map(({ title, items, validation }) => (
                <Fragment key={title}>
                    <h3 className="my-2 text-blue-800 text-xl flex items-center">
                        {title}
                        <SectionProgress validation={validation} />
                    </h3>
                    <dl className="p-3 border-t border-gray-300 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                        <SectionMissingInfo validation={validation} />
                        {Object.values(items).map(({ title, value }) => {
                            if (!value) return null;
                            return (
                                <Fragment key={title}>
                                    <dt className="sm:w-1/2 font-bold py-1">{title}</dt>
                                    <dd className="sm:w-1/2 pl-3 py-1">{value}</dd>
                                </Fragment>
                            );
                        })}
                    </dl>
                </Fragment>
            ))}
        </div>
    );
};

export default UserOnboardingDetails;

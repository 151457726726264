import React, { useState } from 'react';

import { prettyString, prettyDate } from '../../../utils/shared';

const statusColors = {
    APPROVED: 'green',
    PENDING: 'blue',
    REJECTED: 'red',
};

const OrganisationOnboardingDetails = ({
    createdAt,
    leadContactEmail,
    leadContactName,
    leadContactPatientFacing,
    leadContactPhoneNumber,
    onboardingStatus,
    organisationAddressLine1,
    organisationAddressLine2,
    organisationCounty,
    organisationName,
    organisationODSCode,
    organisationPostCode,
    organisationTown,
    systemProficiency,
    systemProficiencyOther,
    prescreenResult,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggle = () => setIsExpanded(!isExpanded);
    const color = statusColors[prescreenResult];

    return (
        <li className="flex flex-col rounded bg-white border border-gray-400 mt-2">
            <button
                onClick={toggle}
                className={`flex flex-col sm:flex-row rounded p-2 pl-3 sm:items-center hover:bg-teal-100 cursor-pointer text-left font-light ${
                    prescreenResult === 'PENDING' ? 'bg-blue-100' : 'bg-gray-100'
                }`}
            >
                <span className="sm:w-1/3">{organisationName}</span>
                <span className="sm:w-1/3">Registered at {prettyDate(createdAt)}</span>
                <span
                    className={`ml-auto inline-block rounded text-sm px-2 py-1 bg-${color}-100 text-${color}-700 border border-${color}-700 capitalize`}
                >
                    {prettyString(prescreenResult)}
                </span>
            </button>
            {isExpanded && (
                <>
                    <h2 className="p-3 pb-0 border-t border-gray-300 text-blue-800 text-lg">
                        Organisation details
                    </h2>
                    <dl className="p-3 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                        <dt className="sm:w-1/3 font-normal">Name</dt>
                        <dd className="sm:w-2/3">{organisationName}</dd>
                        <dt className="sm:w-1/3 font-normal">Address</dt>
                        <dd className="sm:w-2/3">
                            <p>{organisationAddressLine1}</p>
                            <p>{organisationAddressLine2}</p>
                            <p>{organisationTown}</p>
                            <p>{organisationCounty}</p>
                            <p>{organisationPostCode}</p>
                        </dd>
                        <dt className="sm:w-1/3 font-normal">ODS Code</dt>
                        <dd className="sm:w-2/3">{organisationODSCode}</dd>
                        <dt className="sm:w-1/3 font-normal">Patient management systems</dt>
                        <dd className="sm:w-2/3">
                            {[...(systemProficiency || []), systemProficiencyOther].join(', ')}
                        </dd>
                    </dl>
                    <h2 className="p-3 pb-0 border-t border-gray-300 text-blue-800 text-lg">
                        Primary contact details
                    </h2>
                    <dl className="p-3 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                        <dt className="sm:w-1/3 font-normal">Name</dt>
                        <dd className="sm:w-2/3">{leadContactName}</dd>
                        <dt className="sm:w-1/3 font-normal">Patient facing</dt>
                        <dd className="sm:w-2/3">{leadContactPatientFacing ? 'Yes' : 'No'}</dd>
                        <dt className="sm:w-1/3 font-normal">Email address</dt>
                        <dd className="sm:w-2/3">
                            <a
                                className="underline text-teal-600 hover:text-teal-700"
                                href={`mailto:${leadContactEmail}`}
                            >
                                {leadContactEmail}
                            </a>
                        </dd>
                        <dt className="sm:w-1/3 font-normal">Phone number</dt>
                        <dd className="sm:w-2/3">{leadContactPhoneNumber}</dd>
                    </dl>
                </>
            )}
        </li>
    );
};

export default OrganisationOnboardingDetails;

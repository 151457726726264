import React, { useState, useEffect } from 'react';

import Organisation from './OrganisationOnboardingDetails';
import Loader from '../../shared/Loader';
import api from '../../../utils/api';

const sortOrder = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
};

const sortOrganisations = (organisations) =>
    organisations.sort(({ prescreenResult: a }, { prescreenResult: b }) => {
        return sortOrder[a] - sortOrder[b];
    });

const OrganisationOnboardings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [organisations, setOrganisations] = useState([]);

    const getOrganisations = async () => {
        setIsLoading(true);
        try {
            const organisationsResponse = await api.getOnboardingOrganisations();
            setOrganisations(sortOrganisations(organisationsResponse));
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getOrganisations();
    }, []);

    return (
        <section className="p-6 h-full">
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">Organisation onboarding</h1>
            {isLoading && (
                <div className="text-teal-600 flex justify-center my-5">
                    <Loader size={10} />
                </div>
            )}
            {organisations.length > 0 && (
                <ul>
                    {organisations.map((organisation) => (
                        <Organisation {...organisation} key={organisation.onboardingId} />
                    ))}
                </ul>
            )}
        </section>
    );
};

export default OrganisationOnboardings;

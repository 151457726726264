import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UserList from './UserList';
import Tag from '../../shared/Tag';
import Badge from '../../shared/Badge';
import Loader from '../../shared/Loader';
import api from '../../../utils/api';
import { prettyDate } from '../../../utils/shared';
import { prescreenStatusColors, onboardingStatusColors } from '../../../utils/colors';

const renderPrescreenResultTab = (prescreenResult) => (
    <Tag color={prescreenStatusColors[prescreenResult]}>{prescreenResult}</Tag>
);

const renderOnboardingStatusTab = (onboardingStatus) => (
    <Tag color={onboardingStatusColors[onboardingStatus]}>{onboardingStatus}</Tag>
);

const columnHeadings = [
    ['Full name', 'name'],
    ['Registration date', 'createdAt', prettyDate],
    ['Campaign', 'campaign'],
    ['Prescreen status', 'prescreenResult', renderPrescreenResultTab],
    ['Onboarding status', 'onboardingStatus', renderOnboardingStatusTab],
];

const sortOrder = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
};

const sortByPrescreenResult = ({ prescreenResult: a }, { prescreenResult: b }) =>
    sortOrder[a] - sortOrder[b];

const UserOnboardings = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [statusFilters, setStatusFilters] = useState({
        PENDING: true,
        APPROVED: true,
        REJECTED: false,
    });

    const navigate = useNavigate();

    const getUsers = async () => {
        setIsLoading(true);
        try {
            const usersResponse = await api.getOnboardingUsers();
            setUsers(usersResponse.filter((user) => user.prescreenResult));
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getUsers();
    }, []);

    const onChange = ({ target: { id, checked } }) => {
        setStatusFilters({
            ...statusFilters,
            [id]: checked,
        });
    };

    const handleUserClick = (event) => {
        const onboardingId = event.currentTarget.id;
        const selectedUser = users.find((user) => user.onboardingId === onboardingId);

        if (selectedUser.prescreenResult === 'APPROVED') {
            navigate(`/users/role/hcp/userId/${selectedUser.userId}`);
        } else {
            navigate(`/users/onboarding/${selectedUser.onboardingId}`);
        }
    };

    const filteredUsers = users.filter(
        (user) => statusFilters[user.prescreenResult] && user.onboardingStatus !== 'COMPLETE',
    );
    const pendingUserCount = users.filter((user) => user.prescreenResult === 'PENDING').length;

    return (
        <section className="p-6 h-full">
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">Onboarding</h1>
            {isLoading ? (
                <div className="text-teal-600 flex justify-center my-5">
                    <Loader size={10} />
                </div>
            ) : (
                <>
                    <label className="inline-flex items-center mr-4 mb-4 cursor-pointer">
                        <input
                            id="PENDING"
                            type="checkbox"
                            checked={statusFilters['PENDING']}
                            onChange={onChange}
                            className="mr-1"
                        />
                        Pending prescreen
                        {pendingUserCount > 0 && <Badge>{pendingUserCount}</Badge>}
                    </label>
                    <label className="inline-flex items-center mr-4 mb-4 cursor-pointer">
                        <input
                            id="APPROVED"
                            type="checkbox"
                            checked={statusFilters['APPROVED']}
                            onChange={onChange}
                            className="mr-1"
                        />
                        Approved / in progress
                    </label>
                    <label className="inline-flex items-center mr-4 mb-4 cursor-pointer">
                        <input
                            id="REJECTED"
                            type="checkbox"
                            checked={statusFilters['REJECTED']}
                            onChange={onChange}
                            className="mr-1"
                        />
                        Rejected at prescreen
                    </label>
                    <UserList
                        users={filteredUsers}
                        columnHeadings={columnHeadings}
                        defaultSortKey="prescreenResult"
                        identifier="onboardingId"
                        handleUserClick={handleUserClick}
                        customSortFunctions={{
                            prescreenResult: sortByPrescreenResult,
                        }}
                    />
                </>
            )}
        </section>
    );
};

export default UserOnboardings;

import React, { useState } from 'react';
import { Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';
import { OfficeBuilding, Menu, X, Users } from 'heroicons-react';

import Authenticator from './auth/Authenticator';
import UsersByType from './sections/users/UsersByType';
import UserDetails from './sections/users/UserDetails';
import UserPrescreen from './sections/users/UserPrescreen';
import UserOnboardings from './sections/users/UserOnboardings';
import CreateUser from './sections/users/CreateUser';
import OrganisationOnboardings from './sections/organisations/OrganisationOnboardings';
import logo from '../assets/images/docabode_logo.png';

const ENV = process.env.REACT_APP_ENVIRONMENT;

const Home = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    return (
        <div className="flex flex-col h-screen text-gray-700 font-light">
            {ENV !== 'prod' && (
                <div className="bg-teal-600 text-white">
                    <p className="px-6 py-2 text-sm">
                        This is the <strong className="font-bold">{ENV}</strong> environment. Any
                        actions perfomed here will not affect production systems.
                    </p>
                </div>
            )}
            <Authenticator>
                <div className="flex flex-1">
                    <div
                        className={`bg-gray-100 w-3/4 sm:w-1/4 fixed sm:static top-0 h-full border-r border-gray-400 flex flex-col ${
                            menuOpen ? 'left-0' : '-left-3/4'
                        }`}
                    >
                        <a href="/">
                            <img src={logo} className="h-20 mx-auto mt-4" alt="Doc Abode logo" />
                        </a>
                        <p className="text-center text-blue-800 mt-2 mb-4">Management Portal</p>
                        <nav className="border-t border-gray-400 flex flex-col">
                            <NavLink
                                to="/users"
                                className="text-gray-600 py-2 px-4 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                            >
                                <Users />
                                <span className="ml-2">Users</span>
                            </NavLink>
                            {location.pathname.startsWith('/users') && (
                                <>
                                    <NavLink
                                        to="/users"
                                        className="text-gray-600 py-2 pr-3 ml-12 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                                    >
                                        <span className="ml-3 text-sm">Unified</span>
                                    </NavLink>
                                    <NavLink
                                        to="/users/onboarding"
                                        className="text-gray-600 py-2 pr-3 ml-12 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                                    >
                                        <span className="ml-3 text-sm">Onboarding</span>
                                    </NavLink>
                                    <NavLink
                                        to="/users/create"
                                        className="text-gray-600 py-2 pr-3 ml-12 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                                    >
                                        <span className="ml-3 text-sm">Create user</span>
                                    </NavLink>
                                </>
                            )}
                            <NavLink
                                to="/organisations"
                                className="text-gray-600 py-2 px-4 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                            >
                                <OfficeBuilding />
                                <span className="ml-2">Organisations</span>
                            </NavLink>
                            {location.pathname.startsWith('/organisations') && (
                                <NavLink
                                    to="/organisations/onboarding"
                                    className="text-gray-600 py-2 pr-3 ml-12 border-b border-gray-300 flex hover:bg-teal-100 hover:text-teal-600"
                                >
                                    <span className="ml-3 text-sm">Onboarding</span>
                                </NavLink>
                            )}
                        </nav>
                    </div>
                    <div
                        className={`w-full sm:w-3/4 relative sm:static ${
                            menuOpen ? 'left-3/4' : 'left-0'
                        }`}
                    >
                        <div className="sm:hidden border-b border-gray-400 bg-gray-100 px-2 py-1">
                            <button
                                className="rounded py-1 px-2 bg-gray-300 font-light text-gray-700 text-sm hover:bg-gray-400 flex items-center"
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                {menuOpen ? <X /> : <Menu />}
                            </button>
                        </div>

                        <Routes>
                            <Route exact path="/" element={<Navigate to="/users" />} />
                            <Route
                                exact
                                path="/organisations"
                                element={<Navigate to="/organisations/onboarding" />}
                            />
                            <Route path="/users/userId/:userId" element={<UserDetails />} />
                            <Route
                                path="/users/onboarding/:onboardingId"
                                element={<UserPrescreen />}
                            />
                            <Route exact path="/users" element={<UsersByType />} />

                            <Route path="/users/onboarding" element={<UserOnboardings />} />
                            <Route path="/users/create" element={<CreateUser />} />
                            <Route
                                path="/organisations/onboarding"
                                element={<OrganisationOnboardings />}
                            />
                        </Routes>
                    </div>
                </div>
            </Authenticator>
        </div>
    );
};

export default Home;
